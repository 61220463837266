<template>
  <div>
    <PageDynamicBreadcrumb />

    <PageHeader title="Offer Editor" />

    <OfferForm
      type="edit"
      :offer-id="$route.params.offerId"
      @submit="editOffer"
      :submitting="submitting"
    />
  </div>
</template>

<script>
import OfferForm from "@/components/OfferForm";
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";

export default {
  name: "OfferEditor",
  components: {
    PageDynamicBreadcrumb,
    PageHeader,
    OfferForm
  },
  data() {
    return {
      submitting: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    editOffer(offer) {
      this.submitting = true;
      this.$store
        .dispatch("offers/POST_REQUEST", {
          endpoint: "update",
          params: offer
        })
        .then(() => {
          this.triggerActionOutcomeAlert("update", true);
          this.$router.push({ path: "/manage/offers" });
        })
        .catch(() => {
          this.triggerActionOutcomeAlert("update", false);
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
